import './init';

import { tryLoadAndStartRecorder } from '@alwaysmeticulous/recorder-loader';
import { defineOverrideEnv } from '@purple/react-components';
import { createRoot } from 'react-dom/client';
import App from './app';
import './index.css';
import AuthNew from './authNew';
import registerServiceWorker from './registerServiceWorker';

import './i18n'; // needed!

if (import.meta.env.VITE_ENV_DETECTION_OVERRIDE) {
  defineOverrideEnv(import.meta.env.VITE_ENV_DETECTION_OVERRIDE as any);
}

async function startApp() {
  await tryLoadAndStartRecorder({
    // See https://app.meticulous.ai/projects/Sprylab/ACM
    recordingToken: 'GjdsuzSkyBUOW5yz510mMnsWm23UrefN2olwDtwM',
  });

  const container = document.getElementById('root') as HTMLElement;
  const root = createRoot(container);
  if (window.location.href.endsWith('/authnew')) {
    root.render(<AuthNew />);
  } else {
    root.render(<App />);
  }
  registerServiceWorker();
}

// noinspection JSIgnoredPromiseFromCall
startApp();
