import { DropdownOptions } from '@purple/react-components';

export interface Keyword {
  name: string
}

export enum ContentType { // TODO(pg): tmp hard coded until backend implemented
  ARTICLE = 'article',
  VIDEO = 'video',
  // BOOK = "book",
  // DIGITAL = "digital",
  // AUDIO = "audio",
  // TV_SHOW = "tv_show",
  // TV_EPISODE = "tv_episode",
  // TV_SEASON = 'tv_season'
}

export enum ArticleProvider {
  CMS = 'CMS',
  NEWS = 'news',
  WP = 'WordPress', // deprecated
  GOOGLE_NEWS = 'Google News', // deprecated - wird zu News
  FEEDLY = 'FEEDLY', // deprecated - wird zu News
  NEWS_API = 'News API', // deprecated - wird zu News
}

export const API_LOCATION_TYPES: DropdownOptions<string> = [
  {
    value: 'api_query_param',
    label: 'Api Query Param',
  },
  {
    value: 'api_header',
    label: 'Api HTTP Header',
  },
];

export const CONTENT_CONFIG_TYPES: DropdownOptions<string> = [
  {
    value: 'wp_json',
    label: 'WordPress (JSON)',
  },
  {
    value: 'wp_gql',
    label: 'WordPress (GraphQL)',
  },
  {
    value: 'wp_multi',
    label: 'WordPress (Multi)',
  },
  {
    value: 'ext_cms',
    label: 'External CMS',
  },
  {
    value: 'mock_for_testing',
    label: 'None (save to disk)',
  },
];

export const INDEXING_TYPES: DropdownOptions<string> = [
  {
    value: 'push',
    label: 'Push',
  },
  {
    value: 'pull',
    label: 'Pull',
  },
  {
    value: 'push_pull',
    label: 'Push&Pull',
  },
];

export const ENTITY_RECOGNITION_CONFIG: DropdownOptions<string> = [
  {
    value: 'spacy',
    label: 'Spacy',
  },
  {
    value: 'textrazor',
    label: 'Textrazor',
  },
];

export const RECSYS_SUPPORTED_MODELS: DropdownOptions<string> = [
  {
    label: 'Multi-Criteria',
    value: 'multi_criteria',
  },
];

export const INTERFACE_LANGUAGE_OPTIONS: DropdownOptions<string> = [
  {
    value: 'en',
    label: 'en',
  },
  {
    value: 'de',
    label: 'de (partly supported)',
  },
  {
    value: 'none',
    label: 'none (hide all text, for debugging)',
  },
  {
    value: 'cimode',
    label: 'cimode (show translation keys, for debugging)',
  },
];

export const CONFIG_LANGUAGE: DropdownOptions<string> = [
  {
    value: 'de',
    label: 'German',
  },
  {
    value: 'en',
    label: 'English',
  },
];

export const CONFIG_COUNTRY: DropdownOptions<string> = [
  {
    value: 'DE',
    label: 'Germany',
  },
  {
    value: 'UK',
    label: 'United Kingdom',
  },
  {
    value: 'US',
    label: 'United States of America',
  },
];

export const NEWS_CONFIG_TYPES: DropdownOptions<string> = [
  {
    value: 'gn',
    label: 'Google News',
  },
  {
    value: 'feedly',
    label: 'Feedly',
  },
  {
    value: 'news_api',
    label: 'News Api',
  },
  {
    value: 'newsdata_io',
    label: 'NewsData.io',
  },
];

export const LINK_ATTRIBUTE_OPTIONS: DropdownOptions<string> = ['id', 'class'];

export interface SelectedPhraseData {
  selectedText: string
  paragraphText?: string
  paragraphIndex?: number
  occurrence: number
  recommendationId?: string
}

export interface MessageResponse {
  message: string
}
