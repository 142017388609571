import { Box, SxProps } from '@mui/material';
import Typography from '@mui/material/Typography';
import { sxMerge } from '@purple/react-components';
import { ArticleProvider } from '../../constants/interfaces/interfaces';

const DefaultColor = '#999999';
const ProviderColors: Record<ArticleProvider, string> = {
  [ArticleProvider.WP]: '#F79550',
  [ArticleProvider.CMS]: '#F79550',
  [ArticleProvider.FEEDLY]: '#6693C7',
  [ArticleProvider.GOOGLE_NEWS]: '#6693C7',
  [ArticleProvider.NEWS_API]: '#6693C7',
  [ArticleProvider.NEWS]: '#6693C7',
};

type Props = {
  provider: ArticleProvider
  sx?: SxProps
}

export default function SourceBadge(props: Props) {
  const { provider, sx: propsSx } = props;

  const color = ProviderColors[provider] ?? DefaultColor;

  return (
    <Box
      sx={sxMerge({
        height: '18px',
        borderRadius: '4px',
        padding: '1px 6px',
        width: 'fit-content',
        display: 'inline-block',
        backgroundColor: color,
      }, propsSx)}
    >
      <Typography
        sx={{
          color: '#FFF',
          fontSize: '12px',
          lineHeight: '15px',
        }}
      >
        {provider}
      </Typography>
    </Box>
  );
}
